import React, { useRef, useState, useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, Stack, Skeleton, CircularProgress } from '@mui/material'
import { DiscountLabel, ProductName, BrandName, StockLabel } from '../styledComponents/StyledItemComponents'
import DeleteIcon from '@mui/icons-material/Delete'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Currency } from 'react-intl-number-format'
import StyledLink from '../StyledLink'
import { useAppContext } from '../../context/AppProvider'
import PropTypes from 'prop-types'
import { useFavorites } from '../../hooks/useFavorites'
import { useCart } from '../../hooks/useCart'
import { debounce } from 'lodash'
import { ImageLoader } from '../ImageLoader'

export default function MediaControlCard ({ item }) {
  const { state } = useAppContext()
  const { removeItemFromFavorites } = useFavorites()
  const { updateCartItem } = useCart()
  const theme = useTheme()
  const itemData = useRef(item)
  const [addingToCart, setAddingToCart] = useState(false)

  const debouncedCartAction = useCallback(debounce((action, data) => {
    action(data)
    setAddingToCart(false) // Example state update, adjust based on your actual logic
  }, 1000, {
    leading: false,
    trailing: true
  }), [])

  // Handle adding to cart with debounced function
  const handleAddToCart = () => {
    setAddingToCart(true) // Immediate state update for UI feedback
    debouncedCartAction(updateCartItem, itemData)
  }
  return (
    <Card
     sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'space-between' }, width: { xs: '250px', sm: 'auto' }, height: { xs: '450px', sm: '250px', md: '150px' }, marginTop: '20px', padding: '0 30px', flexDirection: { xs: 'column', sm: 'row' } }}>

        <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        { state.favorites.loading
          ? (
                <Box sx={{ minWidth: '220px', minHeight: '450px' }}>
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Box>
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                    <Skeleton animation="wave" sx={{ width: '75%' }}/>
                    <Skeleton animation="wave" sx={{ width: '75%' }}/>
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  </Box>
                </Box>
            )
          : (
          <>
            <Box sx={{ position: 'relative' }}>
            {item.discount.type && (
              <DiscountLabel sx={{ position: 'absolute' }}>
                -{ item.discount.value }{item.discount.type === 'percent' ? '%' : ' RON' }
              </DiscountLabel>
            )}
              <CardMedia
                component="img"
                sx={{ height: '100%', width: '150px', objectFit: 'contain' }}
                image={item.cover}
                alt="Product image"
              />
            </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1' }}>
        {item && (
        <StyledLink to={{ pathname: `/items/${item._id}` }} >

        <ProductName sx={{ fontSize: '14px', WebkitLineClamp: 2, textOverflow: 'ellipsis' }}>
          {item.name}
        </ProductName>
        </StyledLink>
        )}

          <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                { !item.brand?.logo
                  ? <BrandName sx={{ fontSize: '14px' }}>{item.brand?.name}</BrandName>
                  : <ImageLoader src={item.brand.logo} alt={item.brand.name} style={{ height: '18px', marginRight: '5px', marginBottom: '5px', objectFit: 'contain' }}/>
                }
        </Typography>
        <StockLabel sx={{
          position: 'relative',
          left: '0px',
          paddingLeft: '0',
          color: item.stock >= 3
            ? theme.palette.success.main
            : item.stock > 0 && item.stock < 3
              ? theme.palette.warning.main
              : theme.palette.accent.main
        }}>
                  {item.stock >= 3
                    ? 'În stoc!'
                    : item.stock > 0 && item.stock < 3
                      ? 'Stoc limitat!'
                      : 'Stoc furnizor!'}
                </StockLabel>
        </CardContent>
        </Box>
        </>)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <Divider orientation='vertical' variant='middle' flexitem color={theme.palette.fontColor.main} /> */}
          <Stack sx={{ padding: '15px' }}>
          { item.discount?.type
            ? <Box sx={{ alignItems: 'center' }}>
          <Typography variant='h6' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            {item.discount.price}
            </Currency>
          </Typography>
           <Typography variant='h6' sx={{ textDecoration: 'line-through', fontSize: '12px', paddingLeft: '2px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            { item.price }
            </Currency>
            </Typography>
          </Box>
            : <Box sx={{ alignItems: 'center' }}>
          <Typography variant='span' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px' }}>
            <Currency locale="ro-RO" currency="RON">
            {item.price}
            </Currency>
          </Typography>
          </Box>
        }
              <Button
                    variant="contained"
                    size='small'
                    color="accent"
                    startIcon={addingToCart ? <CircularProgress size={24} /> : <ShoppingCartIcon />}
                    disabled={addingToCart || item.stock === 0}
                    onClick={handleAddToCart}
                    sx={{ color: 'white', borderRadius: '18px', height: 'auto' }}
              >
              {addingToCart ? 'Adăugare...' : 'Adaugă în Coș'}
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    size='small'
                    startIcon={<DeleteIcon />}
                    color="success"
                    onClick={() => removeItemFromFavorites(itemData)}
                    sx={{ color: 'success', borderRadius: '16px', height: 'auto', marginTop: '10px' }}
                    >
                  Șterge
                </Button>
              </Box>
              </Stack>
      </Box>
    </Card>
  )
}
MediaControlCard.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
}
