import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from '../theme'
import { Box, Stack, Typography, Divider, Button, IconButton, CircularProgress } from '@mui/material'
import { StyledBox, DiscountLabel, BrandName, StockLabel } from '../components/styledComponents/StyledItemComponents'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ImageSlider from '../components/ImageSlider/ImageSlider'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Currency } from 'react-intl-number-format'
import { useAppContext } from '../context/AppProvider'
import { useItems } from '../hooks/useItems'
import { useFavorites } from '../hooks/useFavorites'
import { useCart } from '../hooks/useCart'
import { debounce } from 'lodash'
import { ImageLoader } from '../components/ImageLoader'

const Item = () => {
  const { state } = useAppContext()
  const { addItemToFavorites, removeItemFromFavorites } = useFavorites()
  const { updateCartItem } = useCart()
  const { itemId } = useParams()
  const { fetchItemsById } = useItems()

  // const itemData = state.items.results.find((item) => (item._id === itemId))
  const [item, setItem] = useState({ loading: false, data: {}, error: null })
  const isItemInFavorites = state.favorites.results?.some(favoriteItem => favoriteItem._id === item.data._id)
  const [addingToCart, setAddingToCart] = useState(false)

  useEffect(() => {
    // if (!itemData || itemData === undefined) {
    fetchItemsById(itemId).then((payload) => {
      setItem({ data: payload.data, loading: false, error: payload.error })
    })
    // }
  }, [itemId])

  // useEffect(() => {
  // }, [state])

  const debouncedCartAction = useCallback(debounce((action, data) => {
    action(data)
    setAddingToCart(false) // Example state update, adjust based on your actual logic
  }, 1000, {
    leading: false,
    trailing: true
  }), [])

  // Handle adding to cart with debounced function
  const handleAddToCart = () => {
    setAddingToCart(true) // Immediate state update for UI feedback
    debouncedCartAction(updateCartItem, item)
  }

  const debouncedFavoriteAction = useCallback(debounce((action, data) => {
    action(data)
  }, 100, {
    leading: false,
    trailing: true
  }), [])

  const handleAddToFavorites = () => {
    const action = isItemInFavorites ? removeItemFromFavorites : addItemToFavorites
    debouncedFavoriteAction(action, item)
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
    { !item.loading &&
      <StyledBox>
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} justifyContent="space-between">
          <Box
            bgcolor={theme.palette.primary.main}
            flex={2}
            p="30px"
            border="solid 1px"
            borderColor={theme.palette.borderColor.main}
            borderRadius="4px"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: '100%', maxHeight: '70vh' }}
          >
            <ImageSlider images={item.data.images} sx={{ width: 'auto', maxWidth: '100%' }} />
          </Box>
          <Box bgcolor={theme.palette.primary.main} flex={3} p='30px 40px' border='solid 1px' borderColor={theme.palette.borderColor.main} borderRadius="4px">
            <Typography variant='h1' fontSize='18px' fontWeight={500} paddingBottom='18px'>{item.data.name}</Typography>
              <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                { !item.data.brand?.logo
                  ? <BrandName>{item.data.brand?.name}</BrandName>
                  : <ImageLoader src={item.data.brand.logo} alt={item.data.brand.name} style={{ height: '18px', marginRight: '5px', marginBottom: '5px', objectFit: 'contain' }}/>
                }
              </Typography>
            <Box>
              <Typography variant='h2' fontSize='16px' fontWeight={500} paddingBottom='16px'>Descriere</Typography>
              <Typography variant='span' fontSize='16px'>
              {item.data.description && item.data.description.map((description, index) => (
                <span key={index}>
                  {description}
                  {index !== item.data.description.length - 1 && <br />} {/* Add line break if it's not the last element */}
                </span>
              ))}
              </Typography>
            </Box>
            <Divider orientation='horizontal' aria-hidden="true" color={theme.palette.fontColor.main}
             sx={{ margin: '20px 0' }}/>
             <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'flex-end' } }}>
              <Box>
              {item.data.discount?.type && (
                <Box sx={{ height: '40px' }}>
                  <DiscountLabel sx={{ top: 0, left: 0 }}>
                    -{ item.data.discount.value }{item.data.discount.type === 'percent' ? '%' : ' RON' }
                  </DiscountLabel>
                </Box>
              )}
               <StockLabel sx={{
                 position: 'relative',
                 left: '0px',
                 paddingLeft: '0',
                 color: item.data.stock >= 3
                   ? theme.palette.success.main
                   : item.data.stock > 0 && item.data.stock < 3
                     ? theme.palette.warning.main
                     : theme.palette.accent.main
               }}>
                  {item.data.stock >= 3
                    ? 'În stoc!'
                    : item.data.stock > 0 && item.data.stock < 3
                      ? 'Stoc limitat!'
                      : 'Stoc furnizor!'}
                </StockLabel>
            {item.data.discount?.type
              ? <Box sx={{ alignItems: 'center' }}>
                      <Typography variant='h6' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px', fontWeight: 400 }}>
                        <Currency locale="ro-RO" currency="RON">
                        {item.data.discount.price}
                        </Currency>
                      </Typography>
                      <Typography variant='h6' sx={{ textDecoration: 'line-through', fontSize: '12px', paddingLeft: '2px', fontWeight: 400 }}>
                        <Currency locale="ro-RO" currency="RON">
                        { item.data.price }
                        </Currency>
                        </Typography>
                      </Box>
              : <Box sx={{ alignItems: 'center' }}>
                      <Typography variant='span' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px' }}>
                        <Currency locale="ro-RO" currency="RON">
                        {item.data.price}
                        </Currency>
                      </Typography>
                      </Box>
                    }
                    </Box>
            <Box sx={{ marginTop: '10px' }}>
                <IconButton aria-label='add to favorites' onClick={handleAddToFavorites}>
                {isItemInFavorites ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                </IconButton>
                <Button onClick={handleAddToCart}
                    variant="contained"
                    size='small'
                    color="accent"
                    disabled={addingToCart || item.data.stock === 0}
                    startIcon={addingToCart ? <CircularProgress size={24} /> : <ShoppingCartIcon />}
                    sx={{ color: 'white', borderRadius: '21px' }}
            >
              {addingToCart ? 'Adaugare...' : 'Adaugă în Coș'}
            </Button>
            </Box>
            </Box>
          </Box>
        </Stack>
      </StyledBox>
    }
    </Box>
  )
}

export default Item
